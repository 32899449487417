import React from 'react';
import { PageLayout } from '../components/PageLayout';

const UeberFrauFreitag = () => {
  return (
    <PageLayout
      title="Über Frau Freitag"
      description="Kafi Freitag wuchs (je nachdem, wie man zählt) als zweites, oder
    sechstes Kind in einem kleinen Dorf nahe Solothurn auf, welches sich
    über Jahre mit japanischen Metropolen um den 1. Platz auf der Liste
    der Selbstmordrate stritt."
    >
      <div className="prose">
        <h1 className="text-lg md:text-xl font-semibold">Über Frau Freitag</h1>
        <p>
          Kafi Freitag wuchs (je nachdem, wie man zählt) als zweites, oder
          sechstes Kind in einem kleinen Dorf nahe Solothurn auf, welches sich
          über Jahre mit japanischen Metropolen um den 1. Platz auf der Liste
          der Selbstmordrate stritt. Dort verlebte sie eine mässig glückliche
          Kindheit und Jugend und nutzte darum die erstbeste Gelegenheit, um dem
          Dorf und der Barockstadt Solothurn den Rücken zu kehren und nach
          Zürich zu ziehen.
        </p>
        <p>
          In Zürich arbeitete sich Kafi ohne Bankausbildung zur Anlageberaterin
          und später zur rechten Hand eines Generaldirektors einer
          internationalen Grossbank hoch. Sie führte eine fürchterliche
          Billig-Modekette und assistierte dem Lehrer einer Heilpädagogischen
          Schule, sie schrieb als Ghostwriterin einen Politik-Blog für die NZZ
          und kleidete einen Politiker ein, der danach zwar Karriere machte, die
          knackig geschnittenen Anzüge jedoch nie trug.
        </p>
        <p>
          Heute führt die glücklich geschiedene 46-jährige Kafi eine
          selbstständige Praxis für prozessorientiertes Coaching & Therapie
          (Freitag Coaching) und gibt Workshops und Seminare für Unternehmungen
          und Organisationen.
        </p>
        <p>
          Kafi hat einen besorgniserregenden Musikgeschmack, welchem sie ihre
          Liebe zum deutschen Wort verdankt. Sie hat eine äusserst pragmatische
          Denkweise und bezeichnet das Autofahren als eine ihrer grössten
          Ressourcen. Sie wohnt mit ihrem 17 Jahre alten Sohn und ihrem Partner
          mitten in Zürich und versucht, ihren Alltag so vernünftig wie nötig
          und amüsant wie möglich zu leben.
        </p>
      </div>
    </PageLayout>
  );
};

export default UeberFrauFreitag;
